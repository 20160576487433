<template>
  <div>
    <div class="top">
      <div class="top_img">
        <img src="../assets/img/PMlogo.png" alt />
      </div>
    </div>
    <div class="mian">
      <div class="box">
        <div class="contant">
          <p class="for_img">
            <img src="../assets/img/nologin.png" alt />
          </p>
          <p class="thank">Thank you for your registration!</p>
          <p
            class="account"
          >Your new account has not been authorized, which needs to be authorized by the administrator. Any doubts please contact office@nantucketbrokers.com.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.top {
  background-color: #678993;
  height: 80px;
  width: 100%;
}
.top_img {
  width: 83%;
  /* border: 1px solid red; */
  margin: 0 auto;
  text-align: left;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.mian{
    width: 100%;
}
.box {
  margin: 0 auto;
  width: 83%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contant{
    width: 60%;
}

.thank,.account{
    font-family: PingFangSC-Medium, sans-serif;
    font-size: 30px;
    color: #333333;
    margin: 0;
}
.thank{
    margin-top: 60px;
}
</style>